import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  setNavigationUpwardTarget,
  setTitle,
  showGlobalProgressModal,
} from '@meeva/service-client-core/redux/interface/actions';
import { ItemInventoryLocalTask, ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import ItemInventoryTaskList from '../common/ItemInventoryTaskList';
import { getShelfName } from './ShelfList';

interface ShelfTasksRouteProps {
  type: string;
  shelfId: string;
  inventoryLocationName?: string;
}

interface ShelfTasksConnectedProps extends ShelfTasksProps, RouteComponentProps<ShelfTasksRouteProps> {
  setTitle: typeof setTitle;
}

interface ShelfTasksProps {
  tasks: Array<[number, ShelfListing]>;
  bulkSetZeroAllowed?: boolean;
}

const ShelfTasks = ({ setTitle, ...props }: ShelfTasksConnectedProps) => {
  const [isLoading, setLoading] = React.useState(true);
  const [shelves, setShelves] = React.useState(new Map<number, ShelfListing>());
  const [shelf, setShelf] = React.useState<ShelfListing | null>(null);
  const [bulkSetZeroAllowed, setBulkSetZeroAllowed] = React.useState(false);
  const [inventoryLocation, setInventoryLocation] = React.useState<[string, string] | undefined>(undefined);
  const [locations, setLocations] = React.useState(new Map<string, string>([]));
  const [shelfTasks, setShelfTasks] = React.useState<ItemInventoryLocalTask[]>([]);

  const { history, tasks } = props;
  const { shelfId, inventoryLocationName } = props.match.params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (shelf) {
      setTitle(getShelfName(shelf));
    } else {
      setTitle(`Regal ${shelfId}`);
    }
  }, [setTitle, shelfId, shelf]);

  useEffect(() => {
    setShelves(new Map(tasks));
  }, [tasks]);

  useEffect(() => {
    const shelf = shelves.get(Number(shelfId));
    setShelf(shelf || null);
    if (!shelf || !inventoryLocation) {
      setShelfTasks([]);
      return;
    }

    const locationEntries = Array.from(locations.entries());
    const currentInventoryLocationIndex = locationEntries.findIndex((item) => item[0] === inventoryLocation[0]);

    // remove shelfTasks without countings in the previous stages
    const previousStages = locationEntries
      .slice(0, currentInventoryLocationIndex)
      .map(([locationName]) => locationName);

    let tasks = shelf.tasks;
    setBulkSetZeroAllowed(previousStages.length > 0);
    if (previousStages.length > 0) {
      tasks = shelf.tasks.filter((task) => {
        return (
          task.countings.length > 0 &&
          previousStages.every((stageName) =>
            task.countings.some(([stockTakeLocation]) => stockTakeLocation === stageName)
          )
        );
      });
    }

    // Regal "Aktionsware" wird anhand der Warengruppe sortiert
    if (shelf.number === -1) {
      tasks.sort(({ item: itemA }, { item: itemB }) => {
        const { productGroup: productGroupA, description: descriptionA } = itemA;
        const { productGroup: productGroupB, description: descriptionB } = itemB;

        if (productGroupA > productGroupB) {
          return 1;
        }

        if (productGroupA < productGroupB) {
          return -1;
        }

        return descriptionA.localeCompare(descriptionB);
      });
    }

    setShelfTasks(tasks);

    setLoading(false);
  }, [tasks, shelfId, setShelfTasks, shelves, inventoryLocation, locations]);

  useEffect(() => {
    const tmpLocations = (process.env.REACT_APP_INVENTORY_LOCATIONS || '')
      .split(',')
      .map((location) => location.split('=', 2)) as Array<[string, string]>;

    setLocations(new Map(tmpLocations));
  }, []);

  useEffect(() => {
    const validLocationNames = Array.from(locations.entries()).map(([name]) => name);
    if (inventoryLocationName && validLocationNames.includes(inventoryLocationName)) {
      const locationIndex = validLocationNames.indexOf(inventoryLocationName);
      if (locationIndex === 0) {
        dispatch(setNavigationUpwardTarget(`/itemInventory/type/${props.match.params.type}`));
      } else {
        dispatch(
          setNavigationUpwardTarget(
            `/itemInventory/type/${props.match.params.type}/shelf/${shelfId}/location/${
              validLocationNames[locationIndex - 1]
            }`
          )
        );
      }
      setInventoryLocation([inventoryLocationName, locations.get(inventoryLocationName)!]);
      return;
    }

    setInventoryLocation(Array.from(locations.entries())[0]);
    dispatch(setNavigationUpwardTarget(`/itemInventory/type/${props.match.params.type}`));
  }, [locations, inventoryLocationName]);

  const onTaskListComplete = React.useCallback(() => {
    if (!inventoryLocation) {
      return;
    }

    const locationEntries = Array.from(locations.entries());
    const currentInventoryLocationIndex = locationEntries.findIndex((item) => item[0] === inventoryLocation[0]);
    if (currentInventoryLocationIndex < locationEntries.length - 1) {
      // move to next location
      history.push(
        `/itemInventory/type/${props.match.params.type}/shelf/${shelfId}/location/${
          locationEntries[currentInventoryLocationIndex + 1][0]
        }`
      );
    } else {
      // show summary
      history.push(`/itemInventory/type/${props.match.params.type}/shelf/${shelfId}/summary`);
    }
  }, [history, inventoryLocation, locations, shelfId]);

  useEffect(() => {
    dispatch(showGlobalProgressModal(isLoading));
  }, [isLoading]);

  if (isLoading || !inventoryLocation) {
    return null;
  }

  return (
    <ItemInventoryTaskList
      shelfId={shelfId}
      tasks={shelfTasks}
      bulkSetZeroAllowed={bulkSetZeroAllowed}
      inventoryLocation={inventoryLocation}
      onListComplete={onTaskListComplete}
    />
  );
};

export default connect(null, {
  setTitle,
})(withRouter(ShelfTasks)) as React.ComponentType<ShelfTasksProps>;
