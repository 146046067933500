import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
import { ItemLabelInterface, ItemLabelsCollectionStatus } from '../interfaces/ItemLabelsCollectionInterface';
import GraphQLClient from '../modules/app/client/dataProvider/apiClient';
import { DB } from '../modules/app/client/dataProvider/storageClient';
import { itemDisplayNumber } from './items';

export const getItemLabelsCollections = async () => {
  await removeOutdatedItemLabelsCollections();

  return DB.itemLabelsCollection.toArray();
};

export const getItemLabelsCollectionsByID = async (id: string) => DB.itemLabelsCollection.get({ id });

export const createItemLabelsCollection = () => {
  const currentDate = new Date();

  return DB.itemLabelsCollection.add({
    id: uuidv4(),
    name: `Etiketten-${currentDate.toJSON().slice(0, 10)} ${`0${currentDate.getHours()}`.slice(-2)}:${`0${currentDate.getMinutes()}`.slice(-2)}`,
    itemLabels: [],
    timestamp: currentDate.toISOString(),
    status: ItemLabelsCollectionStatus.DRAFT,
  });
};

export const updateItemLabelsCollection = async (id: string, name: string, itemLabels: ItemLabelInterface[]) =>
  DB.itemLabelsCollection.update(id, { name, itemLabels });

export const removeItemLabelsCollection = async (id: string) => {
  await DB.itemLabelsCollection.delete(id);
};

export const removeItemLabelByCollectionItemId = async (tableId: string, collectionItemId: string) => {
  await DB.itemLabelsCollection
    .where('id')
    .equals(tableId)
    .modify((itemLabelCollection) => {
      itemLabelCollection.itemLabels.forEach(async (item, i) => {
        if (item.collectionItemId === collectionItemId) {
          itemLabelCollection.itemLabels.splice(i, 1);
        }
      });

      if (!itemLabelCollection.itemLabels.length && itemLabelCollection.status !== ItemLabelsCollectionStatus.SENT) {
        itemLabelCollection.status = ItemLabelsCollectionStatus.DRAFT;
      }
    });
};

export const addItemLabel = async (id: string, barcode: string, quantity = 1) => {
  const item = await DB.items.where('itemNo').equals(barcode).or('scanCodes').equals(barcode).first();

  if (!item) {
    return false;
  }

  if (barcode === item.itemNo) {
    if (item.scanCodes.length === 0) {
      return false;
    }
    barcode = itemDisplayNumber(item, 'gtin').value || barcode;
  }

  await DB.itemLabelsCollection
    .where('id')
    .equals(id)
    .modify((itemLabelsCollection) => {
      // Prüfen, ob der letzte Eintrag derselbe Artikel ist.
      // Falls ja, die Menge erhöhen.
      if (
        itemLabelsCollection.itemLabels.length > 0 &&
        itemLabelsCollection.itemLabels[itemLabelsCollection.itemLabels.length - 1].usedBarcode === barcode
      ) {
        const previousItem = itemLabelsCollection.itemLabels[itemLabelsCollection.itemLabels.length - 1];
        previousItem.quantity ??= 1;
        previousItem.quantity++;
      } else {
        itemLabelsCollection.itemLabels.push({
          ...item,
          usedBarcode: barcode,
          collectionItemId: uuidv4(),
          quantity,
        });
      }

      if (itemLabelsCollection.status !== ItemLabelsCollectionStatus.SENT) {
        itemLabelsCollection.status = ItemLabelsCollectionStatus.READY;
      }
    });

  return item;
};

export const sendItemsToCreatePDF = async (tableId: string): Promise<boolean> => {
  const itemLabelCollection = await getItemLabelsCollectionsByID(tableId);
  const tags: Array<{ item: { id: string }; posIdentity: { posItemId: string }; quantity: number }> = [];

  itemLabelCollection?.itemLabels.forEach(({ id, usedBarcode, quantity }) => {
    tags.push({
      item: { id },
      posIdentity: { posItemId: usedBarcode },
      quantity: quantity ?? 1,
    });
  });

  const CREATE_TAGS = {
    mutation: gql`
      mutation ($name: String, $tags: [ItemLabelTagInput!]!) {
        createItemLabelFile(input: { name: $name, tags: $tags }) {
          success
        }
      }
    `,
    variables: { name: itemLabelCollection?.customName, tags },
  };

  try {
    const client = await GraphQLClient();
    const result = await client.mutate(CREATE_TAGS);
    if (result) {
      await DB.itemLabelsCollection
        .where('id')
        .equals(tableId)
        .modify((itemLabelsCollection) => {
          itemLabelsCollection.status = ItemLabelsCollectionStatus.SENT;
        });

      return result.data.createItemLabelFile.success;
    }
  } catch (e) {
    await DB.itemLabelsCollection
      .where('id')
      .equals(tableId)
      .modify((itemLabelsCollection) => {
        itemLabelsCollection.status = ItemLabelsCollectionStatus.READY;
      });
  }

  return false;
};

export const removeOutdatedItemLabelsCollections = async () => {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  await DB.itemLabelsCollection
    .where('timestamp')
    .below(startOfDay.toISOString())
    .and((entry) => entry.status === ItemLabelsCollectionStatus.SENT)
    .delete();
};
