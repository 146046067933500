import '@fontsource/roboto';
import App from '@meeva/service-client-core/modules/app';
import ItemInventoryModule from '@meeva/service-client-item-inventory';
import { Route } from 'react-router';
import pages from './modules/pages';
import navigations from './modules/navigations';

App.addModule(ItemInventoryModule);
App.addRoutes(pages as unknown as Route[]);
App.addNavigations(navigations);
App.addServiceWorkers([]);

const config = {
  showUserName: true,
  checkDeviceRegistration: true,
  //graphQLUseElasticSearchPagination: true,
  audioFeedback: {
    enabled: true,
    audioOnStatus: ['all'], // 'all' | 'success' | 'warning' | 'error
    audioOnType: ['all'], // 'all' | 'item' | 'businessUnit' | 'container' | 'others'
  },
  logout: {
    syncProcessesThatBlockedLogout: ['stockTakeResults'],
    checkPreLogoutEvent: true,
  },
  itemLoader: {
    merchandiseHierarchyItem: {
      statusType: {
        skip: true,
      },
    },
    sourceForDescription: 'name',
    query: {},
  },
  item: {
    displayNumber: {
      defaultQualifier: 'gtin',
    },
    details: {
      prices: {
        retailPrice: {
          display: true,
          showValidityPeriod: true,
        },
        previousRetailPrice: {
          display: true,
          showValidityPeriod: true,
        },
        permanentPrice: {
          display: true,
          showValidityPeriod: true,
        },
        msrp: {
          display: false,
          showValidityPeriod: true,
        },
      },
    },
  },
  stockTake: {
    enable: true,
    resetNightly: false,
  },
  itemInventory: {
    scanMode: {
      ifItemExistsCountUp: true,
      skipQuantityInputDialog: true,
      disallowQuantityInput: true,
    },
    inventoryItemMenu: {
      reducedMenu: true,
    },
  },
  physicalCount: {
    zoneBarcode: /^ZONE=(\d{4})$/,
    zoneRequired: true,
    zoneCount: true,
    ignoreCurrentStock: true,
    checkPrevQtyConfirmed: false,
    quantityChangeLimit: {
      upperLimit: 9_999,
    },
  },
  missingItemAnalysis: {
    enable: false,
  },
};

App.setConfig(config);

const Element = document.getElementById('root');

if (Element) {
  App.run(Element);
}
