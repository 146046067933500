import { Box, Container, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import { createStyles, makeStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { BorderColor } from '@material-ui/icons';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EventIcon from '@material-ui/icons/Event';
import FreeIcon from '@material-ui/icons/Search';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setNavigationUpwardTarget, setTitle } from '@meeva/service-client-core/redux/interface/actions';
import ItemInventoryTaskForm from './common/ItemInventoryTaskForm';
import ItemInventoryPlanned from './plannedInventory';
import { ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { fetchShelfTasks, setClosingDateEvent, splitShelfTasks } from '../redux/modules/stockTake/operations';
import { setShelfTasks } from '../redux/modules/stockTake/actions';
import { getShelfTasks } from '../redux/modules/stockTake/selectors';
import ItemInventoryFree from './freeInventory';
import ItemDetails from '@meeva/service-client-core/modules/app/items/details';
import { getItemInventoryEvents } from '@meeva/service-client-core/redux/event/selectors';
import { AppMode } from '@meeva/service-client-core/redux/appBehavior/appModeSlice';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import ZoneCount from './zoneCount';
import { hasPermission as hasPermissionSelector } from '@meeva/service-client-core/redux/security/selectors';
import { SessionPermissionRequest } from '@meeva/service-client-core/modules/app/client/authProvider';

const styles = () =>
  createStyles({
    drawer: {
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
    },
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ItemInventoryContainer = (props: RouteComponentProps & WithStyles) => {
  const shelfTasks = useSelector((state) => getShelfTasks(state)) || [];
  const [plannedShelves, setPlannedShelves] = useState<[number, ShelfListing][]>([]);
  const [customShelves, setCustomShelves] = useState<[number, ShelfListing][]>([]);
  const [zoneShelves, setZoneShelves] = useState<[number, ShelfListing][]>([]);
  const [priorityShelvesByReason, setPriorityShelvesByReason] = useState<any>({});

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const shelfTasks: [number, ShelfListing][] = (await fetchShelfTasks()) as any;
      dispatch(setShelfTasks(shelfTasks));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { plannedShelves, priorityShelvesByReason, customShelves, zoneShelves } = (await dispatch(
        splitShelfTasks(shelfTasks)
      )) as any;

      setPlannedShelves(plannedShelves);
      setPriorityShelvesByReason(priorityShelvesByReason);
      setCustomShelves(customShelves);
      setZoneShelves(zoneShelves);
    })();
  }, [shelfTasks]);

  return (
    <Switch>
      <Route path="/itemInventory/task/:taskId/:location" exact component={ItemInventoryTaskForm} />
      <Route
        path="/itemInventory/item/details/byCode/:code"
        render={() => (
          <ItemDetails
            displayPrices={false}
            displayStock={false}
            displayLatestMovements={false}
            enableLabelPrint={false}
            enablePageReturn
            allowStartItemInventory={false}
            allowStartStockMovement={false}
          />
        )}
      />
      <Route path="/itemInventory/type/planned" render={() => <ItemInventoryPlanned tasks={plannedShelves} />} />
      {Object.keys(priorityShelvesByReason).map((reason, key) => (
        <Route
          key={key}
          path={'/itemInventory/type/priority' + reason}
          render={() => <ItemInventoryPlanned tasks={priorityShelvesByReason[reason]} />}
        />
      ))}
      <Route path="/itemInventory/type/free" render={() => <ItemInventoryFree tasks={customShelves} />} />
      <Route path="/itemInventory/zoneCount" render={() => <ZoneCount tasks={zoneShelves} />} />
      <Route
        render={() => (
          <ConnectedItemInventoryIndex
            plannedShelves={plannedShelves}
            priorityShelvesByReason={priorityShelvesByReason}
          />
        )}
      />
    </Switch>
  );
};

interface ItemInventoryIndexProps extends RouteComponentProps {
  setTitle: typeof setTitle;
  plannedShelves: [number, ShelfListing][];
  priorityShelvesByReason: { [key: string]: [number, ShelfListing][] };
}

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ItemInventoryIndex = (props: ItemInventoryIndexProps) => {
  const classes = useStyles();
  const { history, setTitle } = props;
  const dispatch = useDispatch();
  const itemInventoryEvents = useSelector((state) => getItemInventoryEvents(state));
  const hasPermission = useSelector(
    (state) => (request: SessionPermissionRequest) => hasPermissionSelector(state, request)
  );

  //TODO: Implement RootState (@meeva/service-client-core/redux/store) with store.getState types
  const appModeState = useSelector((state: any) => state.app.mode);

  useEffect(() => {
    setTitle(`Inventur`);
    dispatch(setNavigationUpwardTarget(null));
    dispatch(setClosingDateEvent());
  }, [setTitle]);

  const navigateTo = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history]
  );

  const transInstruction = (instruction: string) => {
    switch (instruction) {
      case 'closingDate':
        return 'Stichtagsinventur';
    }
  };

  return (
    <Container>
      <Box mt={1}>
        <Paper>
          <List component="nav">
            <ListItem
              button
              onClick={() => navigateTo('/itemInventory/type/planned')}
              color="primary"
              disabled={
                !props.plannedShelves ||
                props.plannedShelves.length === 0 ||
                AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState
              }
            >
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="Zählung nach Kalender" />
            </ListItem>
            <ListItem
              button
              color="primary"
              disabled={
                !props.priorityShelvesByReason ||
                Object.keys(props.priorityShelvesByReason).length === 0 ||
                AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState
              }
            >
              <ListItemIcon>
                <PriorityHighIcon />
              </ListItemIcon>
              <ListItemText primary="Muss-Artikel" />
            </ListItem>
            {Object.keys(props.priorityShelvesByReason).map((reason, key) => (
              <ListItem
                key={key}
                component="div"
                className={classes.nested}
                button
                onClick={() => navigateTo('/itemInventory/type/priority' + reason)}
                color="primary"
                disabled={AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState}
              >
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText primary={reason} />
              </ListItem>
            ))}
            <ListItem button onClick={() => navigateTo('/itemInventory/type/free')} color="primary">
              <ListItemIcon>
                <FreeIcon />
              </ListItemIcon>
              <ListItemText primary="Freies Scannen" />
            </ListItem>
          </List>
        </Paper>
      </Box>
      {AppConfig.getConfig().physicalCount?.zoneCount &&
        hasPermission({ name: 'meeva_bis_iteminventory:stockTakeZone', canRead: true }) && (
          <Box mt={1}>
            <Paper>
              <ListItem button color="primary" onClick={() => navigateTo('/itemInventory/zoneCount')}>
                <ListItemIcon>
                  <BorderColor />
                </ListItemIcon>
                <ListItemText primary="Zonen Zählung" />
              </ListItem>
            </Paper>
          </Box>
        )}
      {itemInventoryEvents && itemInventoryEvents.length !== 0 && (
        <Box mt={1}>
          <Paper>
            <ListItem>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Inventur-Ereignisse:" />
            </ListItem>
            {itemInventoryEvents.map((event, key) => (
              <ListItem key={key} component="div" className={classes.nested}>
                <ListItemText
                  primary={
                    transInstruction(event.instruction) +
                    ': ' +
                    new Date(event.planStartDate).toLocaleDateString('de-DE') +
                    ' - ' +
                    new Date(event.planEndDate).toLocaleDateString('de-DE') +
                    (event.description ? ' - ' + event.description : '')
                  }
                />
              </ListItem>
            ))}
          </Paper>
        </Box>
      )}
    </Container>
  );
};

const ConnectedItemInventoryIndex = connect(null, { setTitle })(withRouter(ItemInventoryIndex));

export default connect()(withRouter(withStyles(styles)(ItemInventoryContainer))) as React.ComponentType;
